import React from "react";
import Layout from "../components/Layout";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../components/Seo";

const NotFoundPage = props => (
  <Layout location={props.location}>
    <SEO title="404: Not found" />
    <section className="uk-container thankyousection">
      <div className="container content">
        <div className="thankyoudiv">
          <div className="thankyou">
            <h1 class="uk-text-large">404 - Page Not Found!</h1>
            <p className="uk-margin-small-top">
              Oops! The page you are looking for no longer exists.
            </p>
            <AniLink fade to="/">
              <button className="uk-margin-medium-top uk-button uk-button-default">
                Back to Home
              </button>
            </AniLink>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
